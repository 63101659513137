import * as React from 'react'
import { Box, Button, Container, Flex, Image, Text } from '@chakra-ui/react'
import CreateTeamModal from './createTeamModal'

const BookingListTeamItem = ({ team }) => {
	const styles = {
		groupMemberBoard: {
			pSize: '8px',
			pGap: '2px',
			gap: '10px',
			ptGap: '3px',
			container: 'leaderboard',
			rankVariant: 'rank',
			nameVariant: 'ldName',
			scoreVariant: 'score'
		}
	}
	const [isEditOpen, setIsEditOpen] = React.useState(false)

	const { container, imgSize, gap, nameVariant } = styles['groupMemberBoard']

	return (
		<Container variant={container} bg={'#EFC400'} height={'60px'}>
			<Flex
				flex="1"
				direction="row"
				justifyContent={'space-between'}
				overflow="hidden"
				alignItems={'center'}
				mx={gap}
			>
				<Text
					variant={nameVariant}
					color={'black'}
					overflow="hidden"
					textOverflow="ellipsis"
					whiteSpace="nowrap"
				>
					{team.name || ''}
				</Text>
				<Button
					variant={'unstyled'}
					color={'black'}
					fontSize={'sm'}
					onClick={() => setIsEditOpen(true)}
				>
					Edit
				</Button>
			</Flex>
			<CreateTeamModal
				isOpen={isEditOpen}
				groupId={team.groupId}
				team={team}
				setIsOpen={setIsEditOpen}
			/>
		</Container>
	)
}

export default BookingListTeamItem

import React from 'react'
import { Button, Text, Flex, useToast } from '@chakra-ui/react'
import Modal from './modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorToastOptions } from '../helpers'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createTeam, updateTeam } from '../services/team'

import { object, string } from 'yup'
import Input from './input'

const CreateTeamModal = ({
	isOpen,
	groupId,
	setIsOpen,
	leadMemberId,
	team
}) => {
	const queryClient = useQueryClient()
	const toast = useToast()

	const resolver = yupResolver(
		object({
			name: string()
				.trim()
				.required('Team name is required')
				.min(2)
				.max(18)
		}).required()
	)
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			name: team?.name
		}
	})

	const createMutation = useMutation(createTeam, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['groupTeams', groupId])
			setIsOpen(false)
			reset()
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const updateMutation = useMutation(updateTeam, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['groupTeams', groupId])
			setIsOpen(false)
			reset()
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onContinue = (data) => {
		if (team) {
			updateMutation.mutate({
				id: team.id,
				name: data.name,
				avatar: '1'
			})
		} else {
			createMutation.mutate({
				groupId: parseInt(groupId),
				isDefault: false,
				name: data.name,
				leaderId: leadMemberId,
				avatar: '1'
			})
		}
	}

	return (
		<Modal
			isPink
			title={team ? 'Edit Team' : `Create Team`}
			opened={isOpen}
			onClose={() => setIsOpen(false)}
		>
			<Flex flexDir={'column'} gap={5}>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
					color="black"
				>
					{team
						? "Update the name of the team. To delete the team, please remove all of it's members"
						: 'Please set the team name below. A member will be automatically inserted into this team'}
				</Text>

				<form onSubmit={handleSubmit(onContinue)}>
					<Input
						name="name"
						id="name"
						type="name"
						variant="outline"
						focusBorderColor="transparent"
						borderColor="transparent"
						placeholder={'Team Name '}
						mb="10px"
						paddingY={8}
						fontSize={20}
						color="#000000"
						background="#FFFFFF33"
						_placeholder={{ color: '#00000066' }}
						register={register}
					/>

					<Flex justifyContent="center" mb="10px">
						<Text as="span" fontSize="14px" textColor={'red.500'}>
							{errors?.name?.message}
						</Text>
					</Flex>
					<Button
						width={'100%'}
						variant="primary"
						type="submit"
						isLoading={
							createMutation.isLoading || updateMutation.isLoading
						}
					>
						Continue
					</Button>
				</form>
			</Flex>
		</Modal>
	)
}

export default CreateTeamModal

import React, { useEffect, useState, useMemo } from 'react'
import BasePage from '../../../components/basePage'
import { Button, Text, Flex, Container, useToast } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layout'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
	addNewMember,
	getBooking,
	getMembers,
	updateMember
} from '../../../services/booking'
import { getGroupTeams, deleteTeam } from '../../../services/team'
import Spinner from '../../../components/spinner'
import moment from 'moment'
import BookingListItem from '../../../components/bookingListItem'
import { errorToastOptions } from '../../../helpers'

import Modal from '../../../components/modal'
import Input from '../../../components/input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import ErrorStatusModal from '../../../components/errorStatusModal'
import TermsPage from '../../../pagesComponents/login/terms'
import { Textfit } from 'react-textfit'
import BookingListTeamItem from '../../../components/bookingListTeamItem'
import CreateTeamModal from '../../../components/createTeamModal'
import Colors from '../../../@chakra-ui/gatsby-plugin/colors'
import SelectTeamModal from '../../../components/selectTeamModal'

const BookingOverview = ({ bookingId }) => {
	const { isLoading, isError, data } = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getBooking(bookingId),
		retry: false
	})

	const toast = useToast()
	const queryClient = useQueryClient()

	const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false)
	const [isSubmittingNewMember, setIsSubmittingNewMember] = useState(false)

	const { data: membersData } = useQuery({
		queryKey: ['bookingMembers', bookingId],
		queryFn: () => getMembers(bookingId),
		retry: false
	})

	const params =
		typeof window !== 'undefined'
			? new URLSearchParams(window.location.search)
			: null

	const isTeamMode = !!params?.get('teams') || false

	const groupTeamsQuery = useQuery({
		queryKey: ['groupTeams', data?.group?.id],
		queryFn: getGroupTeams,
		enabled: isTeamMode && !!data?.group?.id
	})

	const [modal, setModal] = useState(false)
	const [errorModal, setErrorModal] = useState(null)
	const [selectedMember, setSelectedMember] = useState(null)
	const [toggledMembers, setToggledMembers] = useState([])
	const [assignTeamModalIsOpen, setAssignTeamModalIsOpen] = useState(false)

	const deleteTeamMutation = useMutation(deleteTeam, {
		onSuccess: async (data) => {
			setTimeout(() => {
				queryClient.invalidateQueries(['groupTeams'])
			}, 500)
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const resolver = yupResolver(
		object({
			nickname: string()
				.trim()
				.required('Nickname must be set as appears on leaderboards')
				.min(2)
				.max(18)
			// .matches(/^\S*$/, 'The nickname can not contain spaces')
		}).required()
	)
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			nickname: ''
		}
	})

	useEffect(() => {
		if (isError) {
			setErrorModal('notFound')
		} else if (data && data.outstandingPayment > 0) {
			setErrorModal('outstandingPayment')
		}
		// else if (data && data.checkinStatus !== 'OK') {
		// 	setErrorModal(data.checkinStatus)
		// 	if (data.outstandingPayment > 0) {
		// 		setErrorModal('outstandingPayment')
		// 	}
		// }
	}, [isError, data])

	const title = 'Booking Details'

	const onContinue = async (data) => {
		setIsSubmittingNewMember(true)
		if (modal === 'edit') {
			await updateMember(bookingId, selectedMember.uid, data.nickname)
			if (selectedMember.rfid === null) onLinkRfidCard(selectedMember.uid)
			else setModal(false)
		} else if (modal === 'add') {
			const [newMember] = await addNewMember(bookingId, data.nickname)
			await onLinkRfidCard(newMember.uid)
			setModal(false)
		}

		setIsSubmittingNewMember(false)
	}

	const onLinkRfidCard = async (memberId) => {
		navigate(`/manage-booking/${bookingId}/${memberId}/link-rfid`, {
			state: { rfid: selectedMember?.rfid }
		})
	}

	const showTerms = useMemo(() => {
		return (
			!isLoading &&
			errorModal === 'ok' &&
			data &&
			data.group &&
			!data.group.acceptedTerms
		)
	}, [data, errorModal, isLoading])

	const lastDefaultTeamMember = useMemo(() => {
		if (!!groupTeamsQuery?.data?.length) {
			const index = groupTeamsQuery?.data[0]?.members.length - 1

			if (index === 0) {
				// Lets not make it possible to create if there's only 1 person left in the group
				return null
			}
			return groupTeamsQuery?.data[0]?.members[index]?.memberId
		}

		return null
	}, [groupTeamsQuery?.data])

	if (isLoading) {
		return (
			<Layout title={title}>
				<Flex
					alignItems={'center'}
					justifyContent={'center'}
					height="100vh"
				>
					<Spinner />
				</Flex>
			</Layout>
		)
	}

	const modalView = (
		<Modal
			isPink
			opened={modal}
			onClose={() => setModal(false)}
			title={`${modal} name`}
		>
			<Flex flexDir={'column'} gap={5}>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
				>
					Please edit your nickname below.
				</Text>
				<Text
					fontSize={16}
					fontWeight={'500'}
					px="20px"
					textAlign="center"
					mb="20px"
				>
					Remember your nickname will appear on your leaderboard, so
					make it memorable and keep it clean!
				</Text>
				<form onSubmit={handleSubmit(onContinue)}>
					<Input
						name="nickname"
						id="nickname"
						type="nickname"
						variant="outline"
						focusBorderColor="transparent"
						borderColor="transparent"
						placeholder={
							selectedMember
								? selectedMember.nickname
								: 'Nickname'
						}
						mb="10px"
						paddingY={8}
						fontSize={20}
						background="#FFFFFF33"
						_placeholder={{ color: '#00000066' }}
						register={register}
					/>

					<Flex justifyContent="center" mb="10px">
						<Text as="span" fontSize="14px" textColor={'red.500'}>
							{errors?.nickname?.message}
						</Text>
					</Flex>
					<Button
						width={'100%'}
						variant="primary"
						type="submit"
						isLoading={isSubmittingNewMember}
					>
						Continue
					</Button>
				</form>
				<Button
					onClick={() => {
						if (selectedMember.rfid)
							onLinkRfidCard(selectedMember.uid)
						else setModal(false)
					}}
					variant="link"
					textColor={'black'}
					fontWeight={'400'}
				>
					{selectedMember?.rfid ? 'Link new games card' : 'Cancel'}
				</Button>
			</Flex>
		</Modal>
	)

	const members = [...(membersData?.members || [])]

	while (members.length < data?.memberCount) {
		members.push({ type: 'awaiting' })
	}

	if (showTerms) {
		return <TermsPage booking={data} />
	}

	return (
		<Layout title={title}>
			<BasePage
				theme="dark"
				fullWidthActionBtns
				largeTitleHeading="Welcome to"
				largeTitle={
					<StaticImage
						width={380}
						alt="Logo"
						style={{ marginBottom: 24, width: '200px' }}
						src="../../images/logo-white.png"
					/>
				}
			>
				<Container
					color={'white'}
					display={'flex'}
					alignItems={'center'}
					flexDir={'column'}
					padding={0}
				>
					<Flex flexDir={'column'} gap={1} alignItems={'center'}>
						<Text variant="ml" color="white" textAlign={'center'}>
							Lead Booker: {data?.leadbookerName}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Booking Id: {data?.reservationId}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							{moment(data?.eventDate).format('DD MMMM yyyy')}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Arrival Time: {data?.eventTime}
						</Text>
						<Text variant="ml" color="white" textAlign={'center'}>
							Game Time: {data?.gameTime}
						</Text>
					</Flex>
					{data?.group?.name && (
						<Flex flexDir={'column'} mt={10} gap={1}>
							<Text
								as="h2"
								m={0}
								textAlign={'left'}
								color="white"
							>
								Group Name
							</Text>

							<Container
								variant={'leaderboard'}
								bg={'rgba(255, 255, 255, 0.1)'}
							>
								<Flex
									flex="1"
									direction="column"
									overflow="hidden"
									mx={'2'}
								>
									<Textfit
										mode="single"
										max={36}
										forceSingleModeWidth={true}
										style={{
											zIndex: 1,
											color: 'white',
											fontWeight: 800,
											width: '100%',
											textAlign: 'center'
										}}
									>
										{data?.group?.name}
									</Textfit>
								</Flex>
							</Container>
						</Flex>
					)}

					<Flex flexDir={'column'} mt={10} gap={1}>
						<Text as="h2" m={0} textAlign={'left'} color="white">
							Players
						</Text>
						<Text
							variant="s"
							color="white"
							fontWeight={'400'}
							opacity={0.6}
						>
							Select a tile to add or edit player names
						</Text>

						{isTeamMode ? (
							<div>
								<SelectTeamModal
									isOpen={assignTeamModalIsOpen}
									setIsOpen={setAssignTeamModalIsOpen}
									toggledMembers={toggledMembers}
									teams={groupTeamsQuery?.data}
									setToggledMembers={setToggledMembers}
								/>
								{toggledMembers.length > 0 && (
									<Flex
										position="fixed"
										bottom="0"
										left="0"
										width="100%"
										gap={'4'}
										py="4"
										zIndex={10}
										alignItems={'center'}
										justifyContent={'center'}
										flexDirection={'column'}
										backgroundColor={Colors.pink}
									>
										<Button
											variant={'medium'}
											onClick={() => {
												setAssignTeamModalIsOpen(true)
											}}
										>
											Assign {toggledMembers.length}{' '}
											players to team
										</Button>
										<Button
											variant={'link'}
											textColor="black"
											onClick={() => {
												setSelectedMember([])
												setAssignTeamModalIsOpen(false)
											}}
										>
											Cancel
										</Button>
									</Flex>
								)}
								{groupTeamsQuery?.data?.map((team) => {
									return (
										<div>
											<BookingListTeamItem team={team} />
											{team.members.length < 1 && (
												<Flex
													flexDir="column"
													py="8"
													gap="2"
												>
													<Text textAlign="center">
														No members in team
													</Text>
													{!team.isDefault && (
														<Button
															variant={'link'}
															fontSize={'md'}
															isLoading={
																deleteTeamMutation.isLoading
															}
															onClick={() =>
																deleteTeamMutation.mutate(
																	{
																		teamId: team.id
																	}
																)
															}
														>
															Delete Team
														</Button>
													)}
												</Flex>
											)}

											{members
												.filter(
													(m) =>
														(team.isDefault &&
															m.type ===
																'awaiting') ||
														team.members.find(
															(tm) =>
																Number(
																	tm.memberId
																) ===
																Number(m.userId)
														)
												)
												.map((item, index) => {
													const key = `${index}-${item?.groupId}-booking-list-item`
													const isActive =
														toggledMembers.includes(
															item.userId
														)

													return (
														<BookingListItem
															avatarActivated={
																isActive
															}
															onAvatarClick={() => {
																if (isActive) {
																	setToggledMembers(
																		toggledMembers.filter(
																			(
																				m
																			) =>
																				m !==
																				item.userId
																		)
																	)
																} else {
																	setToggledMembers(
																		[
																			...toggledMembers,
																			item.userId
																		]
																	)
																}
															}}
															onClick={() => {
																setSelectedMember(
																	null
																)
																reset({
																	nickname: ''
																})
																setModal('add')
															}}
															key={key}
															item={item}
														/>
													)
												})}

											{/* {team.members?.map((teamMember) => {
												const matchingMember =
													members.find(
														(member) =>
															Number(
																member.userId
															) ===
															Number(
																teamMember.memberId
															)
													)

												if (!matchingMember) {
													return <></>
												}

												const isActive =
													toggledMembers.includes(
														matchingMember.userId
													)

												return (
													<BookingListItem
														key={teamMember.uid}
														avatarActivated={
															isActive
														}
														onAvatarClick={() => {
															if (isActive) {
																setToggledMembers(
																	toggledMembers.filter(
																		(m) =>
																			m !==
																			matchingMember.userId
																	)
																)
															} else {
																setToggledMembers(
																	[
																		...toggledMembers,
																		matchingMember.userId
																	]
																)
															}
														}}
														onClick={() => {
															if (
																matchingMember.type ===
																'awaiting'
															) {
																setSelectedMember(
																	null
																)
																reset({
																	nickname: ''
																})
																setModal('add')
															} else {
																setSelectedMember(
																	matchingMember
																)
																setValue(
																	'nickname',
																	matchingMember.nickname
																)
																setModal('edit')
															}
														}}
														item={matchingMember}
													/>
												)
											})} */}
										</div>
									)
								})}

								<Button
									variant="square"
									w="100%"
									color="white"
									mt="4"
									onClick={() => {
										setIsCreateTeamModalOpen(true)
									}}
								>
									+ Create team
								</Button>

								{lastDefaultTeamMember && (
									<CreateTeamModal
										isOpen={isCreateTeamModalOpen}
										groupId={data?.group?.id}
										leadMemberId={lastDefaultTeamMember} // We need to send through a member that can be moved to this team
										setIsOpen={setIsCreateTeamModalOpen}
									/>
								)}
							</div>
						) : (
							members.map((item, index) => {
								const key = `${index}-${item?.groupId}-booking-list-item`
								return (
									<BookingListItem
										onClick={() => {
											if (item.type === 'awaiting') {
												setSelectedMember(null)
												reset({ nickname: '' })
												setModal('add')
											} else {
												setSelectedMember(item)
												setValue(
													'nickname',
													item.nickname
												)
												setModal('edit')
											}
										}}
										key={key}
										item={item}
									/>
								)
							})
						)}
					</Flex>
				</Container>
				{modalView}
				<ErrorStatusModal
					setErrorModal={setErrorModal}
					errorModal={errorModal}
				/>
			</BasePage>
			{data?.checkinStatus === 'OK' && (
				<StaticImage
					style={{ width: '100%' }}
					alt="Teddy Bottom"
					src="../../../images/teddy-wedge.png"
				/>
			)}
		</Layout>
	)
}

export default BookingOverview

import * as React from 'react'
import { Box, Button, Container, Flex, Image, Text } from '@chakra-ui/react'
import { forwardRef } from 'react'
import useAvatar from '../helpers/useAvatar'
import { StaticImage } from 'gatsby-plugin-image'
import CheckSVG from '../icons/check-green.svg'

const BookingListItem = forwardRef(
	({ item, onClick, onAvatarClick, avatarActivated }, ref) => {
		const { nickname, name } = item
		let avatar = useAvatar(nickname || name)?.default

		const styles = {
			groupMemberBoard: {
				imgSize: '46px',
				pSize: '8px',
				pGap: '2px',
				gap: '10px',
				ptGap: '3px',
				container: 'leaderboard',
				rankVariant: 'rank',
				nameVariant: 'ldName',
				scoreVariant: 'score'
			}
		}

		const { container, imgSize, gap, nameVariant } =
			styles['groupMemberBoard']

		if (item.type === 'awaiting') {
			return (
				<Container
					onClick={onClick}
					ref={ref}
					variant={container}
					bg={'rgba(255, 255, 255, 0.2)'}
				>
					<Flex w={imgSize}>
						{avatar && (
							<Box
								style={{
									width: imgSize,
									height: imgSize,
									borderRadius: imgSize,
									background: '#C8C8C899'
								}}
							/>
						)}
					</Flex>
					<Flex
						flex="1"
						direction="column"
						overflow="hidden"
						mx={gap}
					>
						<Text
							variant={nameVariant}
							color={'#C8C8C899'}
							overflow="hidden"
							textOverflow="ellipsis"
							whiteSpace="nowrap"
						>
							Enter Player Name
						</Text>
					</Flex>
				</Container>
			)
		}

		return (
			<Container
				ref={ref}
				position={'relative'}
				variant={container}
				bg={'rgba(255, 255, 255, 0.2)'}
			>
				<Flex
					position={'absolute'}
					top="0"
					bottom="0"
					onClick={onClick}
					right="0"
					left="0"
					zIndex={1}
				/>
				<Flex w={imgSize}>
					{avatar &&
						(avatarActivated ? (
							<Box
								onClick={onAvatarClick}
								style={{
									width: imgSize,
									height: imgSize,
									borderRadius: imgSize,
									background: 'green',
									zIndex: 2
								}}
							>
								<CheckSVG width={imgSize} height={imgSize} />
							</Box>
						) : (
							<Image
								alt="Avatar"
								onClick={onAvatarClick}
								style={{
									zIndex: onAvatarClick ? 2 : 0,
									width: imgSize,
									height: imgSize,
									borderRadius: imgSize
								}}
								src={avatar}
							/>
						))}
				</Flex>
				<Flex flex="1" direction="column" overflow="hidden" mx={gap}>
					<Text
						variant={nameVariant}
						color={'white'}
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						{nickname || name || ''}
					</Text>
				</Flex>

				{item.rfid && (
					<StaticImage
						style={{
							height: 35,
							width: 35
						}}
						alt="ok"
						src="../images/icon-connect.png"
					/>
				)}
			</Container>
		)
	}
)

export default BookingListItem
